// Color palette from design
$fd-app-background: #fff;
//fp
$fp-brighter-2: #5e889a;
$fp-brighter-1: #30667d;
$fielddata-primary-fp: #034561;
$fp-darker-1: #033950;
$fp-darker-2: #022c3e;
//fs
$fs-brighter-2: #7ec7e9;
$fs-brighter-1: #54b5e1;
$fielddata-secondary-fp: #149ad7;
$fs-darker-1: #1389c0;
$fs-darker-2: #0f719d;
//scr
$scr-brighter-2: #d37963;
$scr-brighter-1: #c65337;
$spot-color-red: #ba2d0d;
$scr-darker-1: #a0290d;
$scr-darker-2: #771d08;
//scy
$scy-brighter-3: #fce4b9;
$scy-brighter-2: #fdc467;
$scy-brighter-1: #fcb33c;
$spot-color-yellow: #fca311;
$scy-darker-1: #e89204;
$scy-darker-2: #cb7f02;
//scp
$scp-brighter-2: #6bacb3;
$scp-brighter-1: #41959e;
$spot-color-petrol: #177e89;
$scp-darker-1: #136871;
$scp-darker-2: #115c64;
//scg
$scg-brighter-3: #e2e6e8;
$scg-brighter-2: #bcc5c9;
$scg-brighter-1: #a9b4ba;
$spot-color-grey: #96a4ab;
$scg-darker-1: #7b878c;
$scg-darker-2: #60696d;
$scg-darker-3: #606368;

//scgb - gray-blue
$spot-color-grey-blue: #e3e6e9;

// icons colors
$fd-icon-base-color: #655e5eed;

// form values
$fd-label-padding-base: 5px;
$fd-input-left-padding: 4px;

// Buttons values
$fd-button-border-width: 1px;

// fonts
$fd-font-size-xs: 9px;
$fd-font-size-s: 10px;
$fd-font-size-sm: 11px;
$fd-font-size-base: 12px;
$fd-font-size-m: 13px;
$fd-font-size-l: 14px;
$fd-font-size-h2: 16px;
$fd-font-size-h1: 18px;

// Layout values
$fd-page-vertical-padding: 30px;
$fd-page-horizontal-padding: 30px;
$fd-title-margin: 0 0 10px 0;
$fd-button-bar-height: 60px;
$fd-tabs-bar-height: 40px;
$fd-content-area-top-padding: 20px;
$fd-page-left-spacing: 30px;

// Responsive breakpoints

// Smartphones
$screen-xs-min: 320px;
// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;
// Small tablets (portrait view)
$screen-md-min: 768px;
// Tablets and small desktops
$screen-lg-min: 992px;
// Large tablets and desktops
$screen-xl-min: 1200px;
