@import 'variables';

// Layout helpers

%fd-main-title {
  font-size: $fd-font-size-h2;
  margin: $fd-title-margin;
}

%fd-container {
  padding: 0 $fd-page-horizontal-padding 30px $fd-page-horizontal-padding;
  box-sizing: border-box;
}

%fd-flex-row {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
}

%fd-flex-column {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

%fd-content-with-tabs {
  display: block;
  padding: calc(#{$fd-tabs-bar-height + $fd-content-area-top-padding}) $fd-page-horizontal-padding $fd-page-vertical-padding $fd-page-horizontal-padding;
}

%fd-content-with-buttons {
  display: block;
  padding: calc(#{$fd-button-bar-height + $fd-content-area-top-padding}) $fd-page-horizontal-padding $fd-page-vertical-padding $fd-page-horizontal-padding;
}

%fd-content-with-all {
  display: block;
  padding: calc(#{$fd-button-bar-height + $fd-tabs-bar-height + $fd-content-area-top-padding}) $fd-page-horizontal-padding $fd-page-vertical-padding $fd-page-horizontal-padding;
}

%fd-content-with-none {
  display: block;
  padding: $fd-content-area-top-padding $fd-page-horizontal-padding $fd-page-vertical-padding $fd-page-horizontal-padding;
}

// Form styles
%fd-input-text {
  /* Apply when the validations of the input fields fail */
  &.is-invalid {
    border: 1px solid red;

    input[type='text'] {
      color: $scr-darker-2;
    }
  }
}

%fd-input-label {
  color: $scg-brighter-1;
  font-size: $fd-font-size-s;
  font-weight: 400;
  margin-bottom: $fd-label-padding-base;
  box-sizing: border-box;
}

// Buttons
%fd-button {
  padding: 10px 15px;
  min-width: 120px;
  box-sizing: border-box;
  text-align: center;
  font-size: $fd-font-size-m;
  font-weight: 600;
  outline: none;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  border: solid 1px $scg-brighter-3;

  &.xs {
    padding: 5px 10px;
    min-width: 75px;
    font-size: $fd-font-size-s;
    font-weight: 400;
  }

  &.emphasis-l {
    color: $fielddata-primary-fp;
    background: $fd-app-background;

    &:hover {
      background: $scg-brighter-3;
    }

    &:disabled,
    &.disabled {
      background: $fd-app-background;
      color: $scg-brighter-2;

      &:hover {
        background: $fd-app-background;
        color: $scg-brighter-2;
      }
    }
  }

  &.emphasis-m {
    color: $fielddata-secondary-fp;
    border: solid $fd-button-border-width $fielddata-secondary-fp;
    background: $fd-app-background;

    &:hover {
      background: $fielddata-secondary-fp;
      color: $fd-app-background;
    }

    &:disabled,
    &.disabled {
      border: solid $fd-button-border-width $fs-brighter-2;
      background: $fd-app-background;
      color: $fs-brighter-2;

      &:hover {
        background: $fd-app-background;
      }
    }
  }

  &.emphasis-h {
    color: $fd-app-background;
    background: $spot-color-yellow;
    border: solid $fd-button-border-width $spot-color-yellow;

    &:hover {
      background: $scy-darker-1;
      border: solid $fd-button-border-width $scy-darker-1;
    }

    &:disabled,
    &.disabled {
      background: $scy-brighter-2;
      color: $scy-brighter-3;
      border: solid $fd-button-border-width $scy-brighter-2;

      &:hover {
        background: $scy-brighter-2;
      }
    }
  }
}

// Icons
%fd-icons {
  color: $fielddata-primary-fp;

  mat-icon {
    font-size: 20px;
  }
}

// tables
%fd-table {
  border-collapse: collapse;
  width: 100%;
  box-sizing: border-box;
  position: relative;

  th,
  td {
    text-align: left;
    padding: 8px;
  }

  th {
    background-color: $scg-darker-1;
    color: $scg-brighter-3;
    font-size: $fd-font-size-sm;
    font-weight: 500;
    position: sticky;
    top: 0;
  }

  td {
    color: $fielddata-primary-fp;
    font-size: $fd-font-size-sm;
    font-weight: 300;
  }

  tr.sticky-bottom {
    td {
      position: sticky;
      bottom: 0;
      background: $fd-app-background;
    }
  }

  tr:nth-child(even) {
    background-color: $scg-brighter-3;
  }
}

%fd-filled-button {
  color: $fd-app-background;
  font-size: 26px;
  cursor: pointer;
  border: solid $fd-button-border-width $fielddata-primary-fp;
  width: 26px;
  height: 26px;
  border-radius: 3px;
  font-weight: 600;
  background: $fielddata-primary-fp;
  transition: 0.1s all linear;
  user-select: none;

  &:hover {
    background: $fd-app-background;
    color: $fielddata-primary-fp;
    border: solid $fd-button-border-width $fielddata-primary-fp;
  }

  &.inverted-tones {
    color: $fielddata-primary-fp;
    border: solid $fd-button-border-width $fielddata-primary-fp;
    background: $fd-app-background;

    &:hover {
      background: $fielddata-primary-fp;
      color: $fd-app-background;
      border: solid $fd-button-border-width $fielddata-primary-fp;
    }
  }
}
