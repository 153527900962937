@use '@angular/material' as mat;

@import 'normalize.css';
@import 'variables';
@import 'extensions';
@import 'mixins';

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

* {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

body {
  margin: 0;
  height: 100vh;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  overflow-x: hidden;
  -webkit-print-color-adjust: exact;
}

input,
button,
select,
textarea {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

$md-fielddataaccent: (
  50: #f2f4f5,
  100: #e0e4e6,
  200: #cbd2d5,
  300: #b6bfc4,
  400: #a6b2b8,
  500: #96a4ab,
  600: #8e9ca4,
  700: #83929a,
  800: #798991,
  900: #687880,
  A100: #f8fdff,
  A200: #c7eafd,
  A400: #92d9ff,
  A700: #79d0ff,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #000,
    500: #000,
    600: #000,
    700: #000,
    800: #000,
    900: #fff,
    A100: #000,
    A200: #000,
    A400: #000,
    A700: #000
  )
);

$md-fielddataprimary: (
  50: #e1e9ec,
  100: #b3c7d0,
  200: #81a2b0,
  300: #4f7d90,
  400: #296179,
  500: #034561,
  600: #033e59,
  700: #02364f,
  800: #022e45,
  900: #011f33,
  A100: #6bb5ff,
  A200: #389cff,
  A400: #0582ff,
  A700: #0075eb,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #fff,
    400: #fff,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
    A100: #000,
    A200: #000,
    A400: #fff,
    A700: #fff
  )
);

$md-fielddatawarning: (
  50: #f7e6e2,
  100: #eac0b6,
  200: #dd9686,
  300: #cf6c56,
  400: #c44d31,
  500: #ba2d0d,
  600: #b3280b,
  700: #ab2209,
  800: #a31c07,
  900: #941103,
  A100: #ffc3bf,
  A200: #ff928c,
  A400: #ff6259,
  A700: #ff4a40,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #fff,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
    A100: #000,
    A200: #000,
    A400: #000,
    A700: #fff
  )
);

$custom-typography: mat.m2-define-typography-config(
  $font-family: 'Roboto, Helvetica, Arial, sans-serif'
);

$custom-theme-primary: mat.m2-define-palette($md-fielddataprimary);
/* stylelint-disable */
$custom-theme-accent: mat.m2-define-palette($md-fielddataaccent, A200, A100, A400);
/* stylelint-enable */
$custom-theme-warn: mat.m2-define-palette($md-fielddatawarning);

$custom-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $custom-theme-primary,
      accent: $custom-theme-accent,
      warn: $custom-theme-warn
    ),
    typography: $custom-typography
  )
);

@include mat.all-component-themes($custom-theme);

/* Angular material overwrites USE WITH MODERATION - if too different build custom components instead */

.fd-portal-app {
  /* Icon button overwrite */
  .mat-mdc-icon-button {
    height: 25px;
    line-height: 25px;
    padding: 0;
  }

  .mat-mdc-calendar-controls {
    .mat-mdc-icon-button {
      height: 40px;
    }
  }

  mat-label {
    @extend %fd-input-label;

    font-size: $fd-font-size-base;
  }

  .cdk-overlay-pane {
    min-width: 350px;
  }

  .mat-mdc-form-field {
    &.mdc-small-height {
      .mat-mdc-form-field-infix {
        min-height: unset !important;
        padding-top: 12px !important;
        padding-bottom: 12px !important;
      }

      .mdc-line-ripple {
        display: none;
      }
    }
  }

  .mat-mdc-form-field-focus-overlay {
    background: none;
  }

  /* Select options */
  .mat-mdc-option {
    background: $spot-color-grey-blue;
    height: 36px;
    line-height: 36px;
    font-size: $fd-font-size-base;

    .mdc-list-item__primary-text {
      color: $fielddata-primary-fp;
    }

    &.fd-material-warning {
      .mdc-list-item__primary-text {
        color: $spot-color-red;
      }
    }

    &:hover:not(.mdc-list-item--selected) {
      transition: 0.2s background linear;
      background: $fielddata-primary-fp;

      .mdc-list-item__primary-text {
        transition: 0.2s background linear;
        color: $spot-color-grey-blue;
      }
    }

    &.mat-mdc-active:not(.mdc-list-item--selected) {
      transition: 0.2s background linear;
      background: $fielddata-primary-fp;

      .mat-mdc-option-text {
        transition: 0.2s background linear;
        color: $spot-color-grey-blue;
      }
    }

    &.mat-mdc-active &.mat-mdc-select {
      .mat-option-text {
        color: $fielddata-primary-fp;
      }
    }
  }

  .mat-mdc-radio-button.mat-mdc-radio-checked {
    .mdc-radio__inner-circle,
    .mdc-radio__outer-circle {
      border-color: $fielddata-primary-fp !important;
    }
  }

  .mat-mdc-radio-button.mat-accent .mat-mdc-radio-ripple .mat-mdc-ripple-element {
    background-color: rgb(255, 37, 37, 0.26);
  }

  .mat-mdc-radio-label-content {
    width: 80px;
  }

  .mat-mdc-button-toggle-checked {
    background-color: $fielddata-secondary-fp;
  }

  .mat-mdc-sort-header-arrow {
    color: $scg-brighter-3;
  }

  .mat-mdc-form-field-flex,
  .mat-mdc-hint {
    background-color: transparent;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background: none;

    .mdc-text-field__input {
      color: $fielddata-primary-fp;
    }
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;

    &:has(> .mat-mdc-form-field-hint-wrapper > .mat-mdc-form-field-hint) {
      display: block;
    }
  }

  .mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-underline {
    bottom: 1.5em;
  }

  .mat-mdc-input-element,
  .mat-mdc-select-min-line {
    color: $fp-brighter-1;
    width: 100%;
  }

  .mat-mdc-form-field.outlined-field {
    .mat-mdc-form-field-wrapper {
      padding: 0;
      margin: 0;
    }

    .mat-mdc-form-field-outline-start,
    .mat-mdc-form-field-outline-end {
      border-radius: 0;
    }
  }
}

.fd-sticky-tabs.mat-mdc-tab-header {
  position: fixed;
  top: 65px;
  left: 0;
  right: 0;
  z-index: 2;
  background: $fd-app-background;

  .mat-mdc-tab-link-container {
    height: $fd-tabs-bar-height;
    align-items: flex-end;
    background: $spot-color-grey;
    padding: 0 $fd-page-horizontal-padding;
  }

  .mat-mdc-ink-bar {
    display: none;
  }

  .mdc-tab-indicator {
    display: none;
  }

  .mat-mdc-tab-link {
    height: 35px;
    padding: 0;
    font-size: $fd-font-size-base;
    opacity: 1;
    position: relative;
    flex-grow: unset !important;
    min-width: 180px;

    .mdc-tab__text-label {
      color: $fielddata-primary-fp !important;
    }

    &::before {
      margin: 0;
      border-radius: 0;
    }

    &.mat-mdc-tab-disabled {
      opacity: 0.5;

      .mdc-tab__content {
        pointer-events: none;
      }
    }

    &.mdc-tab--active:not(.mat-mdc-tab-disabled) {
      &::after {
        height: 100%;
        transition: none;
      }
    }

    &::after {
      content: '';
      position: absolute;
      display: block;
      bottom: 0;
      left: 0;
      height: 0;
      width: 100%;
      z-index: -1;  /* to be below the parent element */
      background: $fd-app-background;
      transition: 0.2s all ease-in-out;
    }

    &:hover {
      &::after {
        height: 100%;
      }
    }

    /* stylelint-disable */
    &::before {
      content: '';
      position: absolute;
      display: block;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;  /* to be below the parent element */
      background: $scg-brighter-2;
    }
    /* stylelint-enable */

    & + .mat-mdc-tab-link {
      margin-left: 5px;
    }
  }
}

.fd-static-buttons-container {
  position: fixed;
  display: flex;
  top: 65px;
  left: 0;
  right: 0;
  justify-content: flex-start;
  align-items: center;
  min-height: $fd-button-bar-height;
  background: $fd-app-background;
  padding: 0 $fd-page-horizontal-padding;
  box-shadow: 1px 1px 4px 3px $scg-brighter-3;
  z-index: 1;

  &.with-tabs {
    top: 105px;
  }

  .separated-button-section {
    display: block;

    & + .separated-button-section {
      margin-left: 30px;
    }
  }

  .button {
    @extend %fd-button;

    & + .button {
      margin-left: 5px;
    }

    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
}

.fd-search-container {
  @extend %fd-flex-column;

  width: 100%;
  margin: 20px 0;
  position: sticky;
  top: 0;
  padding: 0 0 5px 0;
  background: $fd-app-background;
  z-index: 1;

  .input-text {
    transition: all 0.2s ease-in-out;
    border: solid 2px $fs-brighter-2;
    outline: none;
    font-size: $fd-font-size-l;
    border-radius: 5px;

    &:focus {
      border: solid 2px $fielddata-secondary-fp;
    }
  }

  label {
    color: #000;
  }
}

.mat-mdc-menu-content .mat-mdc-menu-item span.mat-mdc-menu-item-text {
  color: #034561;
  font-size: 11px;
  font-weight: 300;
}

a.error-message-link {
  color: $fielddata-secondary-fp;
}
